import { useState, useEffect, ChangeEvent } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { StyledRow, StyledTitle } from './styles';

import IModuleCubage from '../../../../../shared/interfaces/module-cubage.interface';
import removeNonNumericChars from '../../../../../utils/removeNonNumericChars';
import handleResponseError from '../../../../../utils/handleResponseError';
import CubageHttpService from '../../../../../services/http/cubage.http';
import ProductGroup from '../../../../../constants/productGroup';

interface FormData {
    productErpCode: string;
    modulesForCubage: string;
    cubage: string;
}

interface ModulesCubageModalProps {
    selectedModule: IModuleCubage | null;
    refetch: any;
    show: boolean;
    onHide: () => void;
}

export default function ModulesCubageModal({
    onHide,
    refetch,
    selectedModule,
    show,
}: ModulesCubageModalProps) {
    const [isDisabled, setDisabled] = useState(true);
    const { register, watch } = useForm<FormData>({
        defaultValues: {
            productErpCode: selectedModule?.productErpCode || '',
            modulesForCubage: selectedModule
                ? selectedModule.modulesForCubage.toString()
                : '',
            cubage: selectedModule ? selectedModule.cubage.toString() : '',
        },
    });

    const formData = watch();

    const updateMutation = useMutation(
        async () => {
            if (!selectedModule) {
                throw new Error(
                    'Cubagem do módulo não encontrado para atualizar',
                );
            }

            const modules: any = {
                productErpCode: formData?.productErpCode,
                modulesForCubage: Number(formData?.modulesForCubage),
                cubage: Number(formData?.cubage),
            };

            await CubageHttpService.update(
                ProductGroup.ModulesCubage,
                selectedModule.id,
                modules,
            );

            refetch();
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao salvar cubagem do módulo');
            },
            onSuccess: async () => {
                toast.success('Cubagem do módulo salva com sucesso');
                onHide();
            },
        },
    );

    const createMutation = useMutation(
        async () => {
            const modules: any = {
                productErpCode: formData?.productErpCode,
                modulesForCubage: Number(formData?.modulesForCubage),
                cubage: Number(formData?.cubage),
            };

            await CubageHttpService.create(ProductGroup.ModulesCubage, modules);
            refetch();
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao salvar cubagem do módulo');
            },
            onSuccess: async () => {
                toast.success('Cubagem do módulo salva com sucesso');
                onHide();
            },
        },
    );

    useEffect(() => {
        if (
            formData?.productErpCode?.length > 0 &&
            formData?.modulesForCubage.length > 0 &&
            formData?.cubage.length > 0
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formData]);

    const handleChangeErpCode = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = removeNonNumericChars(e.target.value);
    };

    const isLoading = updateMutation.isLoading || createMutation.isLoading;

    return (
        <Modal
            className="modal-dialog-scrollable"
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    Cubagem do Módulo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100">
                <StyledRow className="ml-0 mt-2 mr-1">
                    <Col>
                        <StyledTitle>Código ERP do módulo</StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="productErpCode"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                            onChange={handleChangeErpCode}
                        />
                    </Col>
                </Row>

                <StyledRow className="ml-0 mt-4 mr-1">
                    <Col>
                        <StyledTitle>
                            Quantidade de módulos para considerar cubagem
                        </StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="modulesForCubage"
                            type="string"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Col>
                </Row>

                <StyledRow className="ml-0 mt-4 mr-1">
                    <Col>
                        <StyledTitle>Cubagem do módulo</StyledTitle>
                    </Col>
                </StyledRow>
                <Row className="ml-0 mt-2 mr-1">
                    <Col>
                        <Form.Control
                            ref={register}
                            name="cubage"
                            type="number"
                            className="form-control-nexen"
                            placeholder="Digite aqui..."
                        />
                    </Col>
                </Row>
                <StyledRow className="ml-0 mt-5 mr-1">
                    <Col sm={6} />
                    <Col>
                        <Button
                            className="w-100 float-right"
                            variant="outline-primary"
                            onClick={() => {
                                onHide();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="w-100 float-right"
                            onClick={() => {
                                if (selectedModule) {
                                    updateMutation.mutate();
                                } else {
                                    createMutation.mutate();
                                }
                            }}
                            disabled={isDisabled}
                        >
                            Salvar{'   '}
                            {isLoading && (
                                <Spinner animation="border" size="sm" />
                            )}
                        </Button>
                    </Col>
                </StyledRow>
            </Modal.Body>
            <Modal.Footer className="ml-3 mr-3 mb-2" />
        </Modal>
    );
}
