/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { theme, control } from '../../../styles/react-select-config';
import { notificationMethodsStyles } from '../../../pages/Users/styles';
import { cnpjMask, cpfMask } from '../../../utils/masks';
import { OptionType } from '../../../pages/Users/New';

interface Props {
    user: any;
    errors: any;
    roles: OptionType[];
    regions: OptionType[];
    userRoles: OptionType[];
    userRegions: OptionType[];
    notificationMethods: OptionType[];
    notificationMethodsList: OptionType[];
    handleChanges: (event: any) => void;
    setUserRoles: React.Dispatch<React.SetStateAction<OptionType[]>>;
    setUserRegions: React.Dispatch<React.SetStateAction<OptionType[]>>;
    handleChangesNotificationMethods: (
        value: any,
        { action, removedValue }: any,
    ) => void;
}

const UserInfo = (props: Props) => {
    return (
        <Row className="pl-2 pr-2">
            <Col>
                <div>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.username}
                            onChange={props.handleChanges}
                            value={props.user.username}
                            name="username"
                            type="text"
                            placeholder="Informe o nome"
                        />
                        {props.errors.username && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.username}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            onChange={props.handleChanges}
                            value={props.user.email}
                            isInvalid={!!props.errors.email}
                            name="email"
                            type="email"
                            placeholder="Informe seu e-mail"
                            maxLength={256}
                        />
                        {props.errors.email && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.email}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.password}
                            onChange={props.handleChanges}
                            name="password"
                            type="password"
                            placeholder="Informe a senha"
                        />
                        {props.errors.password && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.password}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Label>Confirmar senha</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.password_confirmation}
                            onChange={props.handleChanges}
                            name="password_confirmation"
                            type="password"
                            placeholder="Confirmar senha"
                        />
                        {props.errors.password_confirmation && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.password_confirmation}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="formBasicPermission">
                        <Form.Label>Permissões</Form.Label>

                        <Select
                            onChange={(e: any) => {
                                props.setUserRoles(e);
                            }}
                            value={props.userRoles}
                            styles={{ control }}
                            options={props.roles}
                            isMulti
                            theme={theme}
                        />
                        {props.errors.roles && (
                            <p
                                style={{
                                    color: 'red',
                                }}
                            >
                                {' '}
                                {props.errors.roles}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group controlId="formBasicIe">
                        <Form.Label>Regiões</Form.Label>

                        <Select
                            onChange={(e: any) => {
                                props.setUserRegions(e);
                            }}
                            value={props.userRegions}
                            styles={{ control }}
                            options={props.regions}
                            isMulti
                            theme={theme}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicIe">
                        <Form.Label>Meios de notificação</Form.Label>

                        <Select
                            onChange={(e, { action, removedValue }) =>
                                props.handleChangesNotificationMethods(e, {
                                    action,
                                    removedValue,
                                })
                            }
                            isClearable={props.notificationMethods?.some(
                                (v: any) => !v.isFixed,
                            )}
                            value={props.notificationMethods}
                            styles={notificationMethodsStyles}
                            options={props.notificationMethodsList}
                            isMulti
                            theme={theme}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicMargin">
                        <Form.Check
                            id="switchMargin"
                            name="canViewMarginLiquid"
                            type="switch"
                            checked={props.user.canViewMarginLiquid || false}
                            className="float-left"
                            onChange={props.handleChanges}
                        />

                        <Form.Label>
                            Pode visualizar margem liquida do pedido
                        </Form.Label>
                    </Form.Group>

                    <Form.Group controlId="formBasicIe">
                        <Form.Label>CNPJ</Form.Label>

                        <Form.Control
                            disabled={
                                props.userRoles === null ||
                                !props.userRoles.find(
                                    (e: any) =>
                                        e?.reference === 'SHIPPING_COMPANY',
                                )
                            }
                            value={props.user.cnpj}
                            isInvalid={
                                props.userRoles !== null &&
                                props.userRoles.find(
                                    (e: any) =>
                                        e?.reference === 'SHIPPING_COMPANY',
                                ) &&
                                !!props.errors.cnpj
                            }
                            as={MaskedInput}
                            mask={cnpjMask}
                            onChange={props.handleChanges}
                            name="cnpj"
                            type="text"
                            placeholder="Informe o CNPJ"
                        />
                        {props.errors.cnpj && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.cnpj}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formCpf">
                        <Form.Label>CPF</Form.Label>

                        <Form.Control
                            disabled={
                                props.userRoles === null ||
                                !props.userRoles.find((e: any) =>
                                    [
                                        'COMMERCIAL',
                                        'COMMERCIAL_SUPERVISOR',
                                    ].includes(e?.reference),
                                )
                            }
                            value={props.user.cpf}
                            isInvalid={
                                props.userRoles !== null &&
                                props.userRoles.find((e: any) =>
                                    [
                                        'COMMERCIAL',
                                        'COMMERCIAL_SUPERVISOR',
                                    ].includes(e?.reference),
                                ) &&
                                !!props.errors.cnpj
                            }
                            as={MaskedInput}
                            mask={cpfMask}
                            onChange={props.handleChanges}
                            name="cpf"
                            type="text"
                            placeholder="Informe o CPF"
                        />
                        {props.errors.cnpj && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.cnpj}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
            </Col>
        </Row>
    );
};

export default UserInfo;
