import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Col,
    Nav,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import { Attachment } from '../../components/Integrator/IntegratorDocuments';
import {
    documentTypes,
    documentTypesTranslate,
} from '../../constants/documents';
import NotificationIcon from '../../constants/notificationIcon';
import Role from '../../constants/roles';
import sellerStatus from '../../constants/sellerStatus';
import { AuthValues, useAuth } from '../../contexts/authContext';
import { integratorListRoute } from '../../routes/config';
import history from '../../services/history';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import formatLevel from '../../utils/formatLevel';
import userHasRoles from '../../utils/userHasRoles';
import {
    StyledApproveButton,
    StyledBadge,
    StyledCol,
    StyledDisabledApproveButton,
    StyledDiv,
    StyledH4,
    StyledH5,
    StyledH6,
    StyledLink,
    StyledNav,
    StyledReproveButton,
    StyledReturnButton,
} from './styles';
import handleResponseError from '../../utils/handleResponseError';
import BrasilService from '../../services/http/brasil.http';
import IntegratorHttpService from '../../services/http/integrator-http';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';

const View: React.FC = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)',
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1223px)' });

    const { user }: AuthValues = useAuth();
    const isRegistration = userHasRoles(user, [Role.Registration]);
    const isAdmin = userHasRoles(user, [Role.Administrator]);

    const newIntegrator: any = () => ({
        id: '',
        name: '',
        email: '',
        cnpj: '',
        level: '',
        phone: '',
        profilePoints: 0,
        profile: '',
        ie: '',
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: '',
        erpCityName: '',
        fancyName: '',
        status: '',
        observation: '',
        partnerName: '',
        partnerDocument: '',
        partnerBirthDate: '',
        partnerEmail: '',
        partnerPhone: '',
        hasAttachments: false,
        attachments: null,
        digitalContract: null,
    });

    const { id } = useParams<{ id: string }>();
    const [integrator, setIntegrator] = useState(newIntegrator());
    const [errorOccur, setErrorOccur] = useState(false);
    const [documentNamingError, setDocumentNamingError] = useState(false);
    const [documentPreview, setDocumentPreview] = useState<
        Attachment | undefined
    >();
    const [tab, setTab] = useState('');

    const [showApproveIntegratorModal, setShowApproveIntegratorModal] =
        useState<boolean>(false);

    const [showReprovalObservationModal, setShowReprovalObservationModal] =
        useState(false);

    function titleCase(text: string) {
        return (
            text
                .toLowerCase()
                .split(' ')
                // eslint-disable-next-line func-names
                .map(function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(' ')
        );
    }

    function getContractToAttachment(clickSignDocuments: any) {
        const contract = clickSignDocuments?.find(
            (item: any) =>
                item.documentType === documentTypes.TermsAndConditions &&
                item.signedFileUrlAws,
        );
        if (!contract) return undefined;

        const contractArray = contract.signedFileUrlAws
            .split('/')[2]
            .split('.');
        const contractName = contractArray[0];
        const contractExtension = contractArray[1];

        const attachmentContract: Attachment = {
            awsLink: contractName,
            name: contractName,
            extension: `.${contractExtension}`,
            type: contract.documentType,
        };
        return attachmentContract;
    }

    useEffect(() => {
        async function loadIntegrator(): Promise<void> {
            try {
                const response = await IntegratorHttpService.readOne(id);

                const { data } = response;

                const level = titleCase(formatLevel(data.level));

                const states = await BrasilService.getStates();

                const state = states.find(
                    (item) => item.sigla === data.erpState,
                );
                const document = getContractToAttachment(
                    data.clickSignDocuments,
                );
                // eslint-disable-next-line no-restricted-syntax
                for (const attachment of data.attachments) {
                    if (
                        data.attachments.some(
                            (otherAttachment: Attachment) =>
                                attachment !== otherAttachment &&
                                attachment.awsLink === otherAttachment.awsLink,
                        )
                    ) {
                        setDocumentNamingError(true);
                        break;
                    }
                }

                setIntegrator({
                    id,
                    name: data.name,
                    email: data.user.email,
                    cnpj: data.cnpj,
                    level,
                    phone: data.phone,
                    profilePoints: data.profilePoints,
                    profile: data.profile,
                    ie: data.ie,
                    erpCityName: data.erpCityName,
                    erpState: state?.nome || '',
                    addressCep: data.addressCep?.toString() || '',
                    addressNumber: data.addressNumber,
                    addressDescription: data.addressDescription,
                    addressNeighborhood: data.addressNeighborhood,
                    addressComplement: data.addressComplement,
                    fancyName: data.fancyName,
                    status: data.status,
                    observation: data.observation,
                    partnerName: data.companyInfo?.partnerName,
                    partnerDocument: data.companyInfo?.partnerDocument,
                    partnerBirthDate: data.companyInfo?.partnerBirthDate,
                    partnerEmail: data.companyInfo?.partnerEmail,
                    partnerPhone: data.companyInfo?.partnerPhone,
                    hasAttachments: data.hasAttachments,
                    attachments: data.attachments,
                    digitalContract: document,
                });

                const cpfRg = data.attachments?.find(
                    (attachment: Attachment) =>
                        attachment.type === documentTypes.CpfRg,
                );

                const socialContract = data.attachments?.find(
                    (attachment: Attachment) =>
                        attachment.type === documentTypes.SocialContract,
                );

                const addressReceipt = data.attachments?.find(
                    (attachment: Attachment) =>
                        attachment.type === documentTypes.AddressReceipt,
                );

                if (document) {
                    setTab(document.name);
                    setDocumentPreview(document);
                } else if (cpfRg) {
                    setTab(cpfRg.awsLink);
                    setDocumentPreview(cpfRg);
                } else if (socialContract) {
                    setTab(socialContract.awsLink);
                    setDocumentPreview(socialContract);
                } else if (addressReceipt) {
                    setTab(addressReceipt.awsLink);
                    setDocumentPreview(addressReceipt);
                } else {
                    setTab('');
                    setDocumentPreview(undefined);
                }
            } catch (error) {
                console.error(error);
                setErrorOccur(true);
            }
        }

        loadIntegrator();
    }, [id]);

    const updateStatus = useMutation(
        async (params: {
            sellerId: number;
            status: sellerStatus;
            observation: string;
        }) =>
            IntegratorHttpService.updateStatus(
                params.sellerId,
                params.status,
                params.observation,
            ),
        {
            onError: (error) => {
                handleResponseError(
                    error,
                    'Erro ao atualizar o status do cadastro do integrador',
                );
            },
            onSuccess: () => {
                toast.success('Cadastro atualizado com sucesso');
            },
            onSettled: () => {
                history.push(integratorListRoute.path);
            },
        },
    );

    async function handleStatusChange(
        status: sellerStatus,
        observation?: string,
    ) {
        updateStatus.mutate({
            sellerId: Number(id),
            status,
            observation: observation || '',
        });
    }

    const goToEditPage = async () => {
        history.push(`edit`);
    };

    const colors = {
        darkGrey: '#707070',
        lightGrey: '#bbbbbb',
    };

    const downloadDocument = (file: Attachment) => {
        if (!file.type) return '';

        return IntegratorHttpService.downloadDocumentLink(
            file.type,
            `${file?.awsLink}${file?.extension}`,
        );
    };

    const filePreview = useQuery({
        queryKey: ['file-preview', documentPreview],
        queryFn: async () => {
            if (!documentPreview?.type) return null;

            const response = await IntegratorHttpService.getDocument(
                documentPreview.type,
                `${documentPreview?.awsLink}${documentPreview?.extension}`,
            );

            return `data:application/pdf;base64,${response.data}`;
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message as string);
            } else {
                toast.error('Ocorreu um erro ao buscar documento');
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        refetchOnReconnect: false,
        retry: false,
    });

    const getStatusBadge = () => {
        switch (integrator.status) {
            case sellerStatus.Disabled:
                return <Badge variant="info">Bloqueado</Badge>;
            case sellerStatus.Reproved:
                return (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="auto-end"
                        overlay={
                            <Tooltip id="observation">
                                {`Motivo: ${
                                    integrator.observation || 'Não informado'
                                }`}
                            </Tooltip>
                        }
                    >
                        <Badge
                            style={{
                                cursor: 'default',
                            }}
                            variant="danger"
                        >
                            Reprovado
                        </Badge>
                    </OverlayTrigger>
                );

            case sellerStatus.Approved:
                return <Badge variant="success">Ativo</Badge>;
            case sellerStatus.Pending:
                return <Badge variant="warning">Pendente</Badge>;
            case sellerStatus.Processing:
                return <Badge variant="warning">Processando</Badge>;
            default:
                return <Badge variant="secondary">-</Badge>;
        }
    };

    const getProfile = () => {
        if (!integrator.profilePoints) {
            return 'Perfil Não Definido';
        }

        if (integrator.profilePoints <= 40) {
            return 'Integrador Volume';
        }

        if (integrator.profilePoints <= 70) {
            return 'Integrador Crescimento';
        }

        return 'Integrador Valor';
    };

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <StyledPageTitle className="mt-2">
                                Integradores
                            </StyledPageTitle>
                            <StyledPageSubTitle>
                                Todas as informações dos integradores em um só
                                lugar.
                            </StyledPageSubTitle>
                        </div>
                    </div>
                </Col>

                {!errorOccur &&
                    integrator.status === sellerStatus.Pending &&
                    (isAdmin || isRegistration) && (
                        <>
                            <StyledCol sm={2} md={2} lg={2} xl={1}>
                                <StyledReproveButton
                                    type="button"
                                    onClick={() =>
                                        setShowReprovalObservationModal(true)
                                    }
                                    disabled={updateStatus.isLoading}
                                >
                                    {updateStatus.isLoading ? (
                                        <Spinner animation="border" />
                                    ) : (
                                        'Reprovar cadastro'
                                    )}
                                </StyledReproveButton>
                            </StyledCol>

                            <StyledCol sm={2} md={2} lg={2} xl={1}>
                                {!integrator.digitalContract ||
                                !integrator.hasAttachments ? (
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-bottom">
                                                Não é possível aprovar o
                                                cadastro, pois o integrador não
                                                possui toda a documentação
                                                necessária
                                            </Tooltip>
                                        }
                                    >
                                        <StyledDisabledApproveButton type="button">
                                            Aprovar cadastro
                                        </StyledDisabledApproveButton>
                                    </OverlayTrigger>
                                ) : (
                                    <StyledApproveButton
                                        type="button"
                                        onClick={() => {
                                            setShowApproveIntegratorModal(true);
                                        }}
                                        disabled={updateStatus.isLoading}
                                    >
                                        {updateStatus.isLoading ? (
                                            <Spinner animation="border" />
                                        ) : (
                                            'Aprovar cadastro'
                                        )}
                                    </StyledApproveButton>
                                )}
                            </StyledCol>
                        </>
                    )}

                {!errorOccur &&
                    integrator.status === sellerStatus.Reproved &&
                    (isAdmin || isRegistration) && (
                        <>
                            <StyledCol sm={4} md={3} lg={3} xl={2}>
                                <StyledReturnButton
                                    type="button"
                                    onClick={() =>
                                        handleStatusChange(sellerStatus.Pending)
                                    }
                                    disabled={updateStatus.isLoading}
                                >
                                    {updateStatus.isLoading ? (
                                        <Spinner animation="border" />
                                    ) : (
                                        'Retornar cadastro para pendente'
                                    )}
                                </StyledReturnButton>
                            </StyledCol>
                        </>
                    )}

                {!errorOccur && (
                    <StyledCol sm={2} md={2} lg={2} xl={1}>
                        <Button
                            type="button"
                            onClick={goToEditPage}
                            style={{ width: '100%', padding: '6px 6px' }}
                            disabled={
                                integrator.status === sellerStatus.Disabled
                            }
                        >
                            Editar
                        </Button>
                    </StyledCol>
                )}
            </Row>

            {errorOccur ? (
                <Row className="pl-2 pr-2 mt-4">
                    <Col>
                        <StyledH6 color={colors.lightGrey}>
                            NÃO FOI POSSÍVEL BUSCAR AS INFORMAÇÕES DO INTEGRADOR
                        </StyledH6>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row className="pl-2 pr-2 mt-4">
                        <Col>
                            <StyledH4>
                                <StyledLink to={integratorListRoute.path}>
                                    <i className="fas fa-chevron-left mr-2" />
                                    <b>{integrator.name?.toUpperCase()}</b>
                                </StyledLink>
                            </StyledH4>
                            {getStatusBadge()}{' '}
                            <StyledBadge>{integrator.level}</StyledBadge>{' '}
                            <StyledBadge>{getProfile()}</StyledBadge>
                        </Col>
                    </Row>

                    {isDesktopOrLaptop && (
                        <Row className="pl-2 pr-4 mt-4">
                            <Col xs={3}>
                                <StyledH5 weight="bold">
                                    Dados da Empresa
                                </StyledH5>

                                <StyledH6 color={colors.lightGrey}>
                                    CNPJ
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.cnpj
                                        ? `${integrator.cnpj.slice(
                                              0,
                                              2,
                                          )}.${integrator.cnpj.slice(
                                              2,
                                              5,
                                          )}.${integrator.cnpj.slice(
                                              5,
                                              8,
                                          )}/${integrator.cnpj.slice(
                                              8,
                                              12,
                                          )}-${integrator.cnpj.slice(12, 14)}`
                                        : '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    RAZÃO SOCIAL
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.name?.toUpperCase() || '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    NOME FANTASIA
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.fancyName?.toUpperCase() || '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    INSCRIÇÃO ESTADUAL
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.ie || '-'}
                                </StyledH6>

                                <StyledH5
                                    weight="bold"
                                    style={{ marginTop: '40px' }}
                                >
                                    Dados do Administrador
                                </StyledH5>

                                <StyledH6 color={colors.lightGrey}>
                                    NOME COMPLETO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.partnerName?.toUpperCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    CPF
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.partnerDocument || '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    DATA DE NASCIMENTO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.partnerBirthDate
                                        ? `${integrator.partnerBirthDate.slice(
                                              -2,
                                          )}/${integrator.partnerBirthDate.slice(
                                              5,
                                              -3,
                                          )}/${integrator.partnerBirthDate.slice(
                                              0,
                                              4,
                                          )}`
                                        : '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    E-MAIL
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.partnerEmail?.toLowerCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    WHATSAPP
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.partnerPhone || '-'}
                                </StyledH6>
                            </Col>

                            <Col xs={2}>
                                <StyledH5 weight="bold">Endereço</StyledH5>

                                <StyledH6 color={colors.lightGrey}>
                                    LOGRADOURO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.addressDescription?.toUpperCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    NÚMERO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.addressNumber?.toUpperCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    COMPLEMENTO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.addressComplement?.toUpperCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    BAIRRO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.addressNeighborhood?.toUpperCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    CIDADE
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.erpCityName?.toUpperCase() ||
                                        '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    ESTADO
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.erpState?.toUpperCase() || '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    CEP
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.addressCep
                                        ? `${integrator.addressCep.slice(
                                              0,
                                              5,
                                          )}-${integrator.addressCep?.slice(
                                              5,
                                              8,
                                          )}`
                                        : '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    TELEFONE
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.phone
                                        ? `(${integrator.phone?.slice(
                                              0,
                                              2,
                                          )}) ${integrator.phone?.slice(
                                              2,
                                              -4,
                                          )}-${integrator.phone?.slice(-4)}`
                                        : '-'}
                                </StyledH6>

                                <StyledH6 color={colors.lightGrey}>
                                    E-MAIL
                                </StyledH6>
                                <StyledH6 color={colors.darkGrey}>
                                    {integrator.email?.toLowerCase() || '-'}
                                </StyledH6>
                            </Col>

                            <Col xs={2} style={{ textAlign: 'right' }}>
                                <StyledH5 weight="bold">Documentação</StyledH5>

                                {!documentNamingError && (
                                    <StyledNav
                                        variant="tabs"
                                        activeKey={tab}
                                        className="flex-column"
                                        onSelect={(selectedKey: string) =>
                                            setTab(String(selectedKey))
                                        }
                                    >
                                        {integrator.digitalContract && (
                                            <Nav.Item
                                                key={
                                                    documentTypes.TermsAndConditions
                                                }
                                            >
                                                <Nav.Link
                                                    eventKey={
                                                        integrator
                                                            .digitalContract
                                                            .name
                                                    }
                                                    onClick={() =>
                                                        setDocumentPreview(
                                                            integrator.digitalContract,
                                                        )
                                                    }
                                                >
                                                    Termos e Condições
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}

                                        {integrator.attachments?.length > 0 &&
                                            integrator.attachments
                                                .filter(
                                                    (attachment: Attachment) =>
                                                        attachment.type ===
                                                        documentTypes.CpfRg,
                                                )
                                                .map(
                                                    (
                                                        file: Attachment,
                                                        index: number,
                                                    ) => (
                                                        <Nav.Item key={index}>
                                                            <Nav.Link
                                                                eventKey={
                                                                    file.awsLink
                                                                }
                                                                onClick={() =>
                                                                    setDocumentPreview(
                                                                        file,
                                                                    )
                                                                }
                                                            >
                                                                CPF e RG
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ),
                                                )}

                                        {integrator.attachments?.length > 0 &&
                                            integrator.attachments
                                                .filter(
                                                    (attachment: Attachment) =>
                                                        attachment.type ===
                                                        documentTypes.SocialContract,
                                                )
                                                .map(
                                                    (
                                                        file: Attachment,
                                                        index: number,
                                                    ) => (
                                                        <Nav.Item key={index}>
                                                            <Nav.Link
                                                                eventKey={
                                                                    file.awsLink
                                                                }
                                                                onClick={() =>
                                                                    setDocumentPreview(
                                                                        file,
                                                                    )
                                                                }
                                                            >
                                                                Contrato Social
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ),
                                                )}

                                        {integrator.attachments?.length > 0 &&
                                            integrator.attachments
                                                .filter(
                                                    (attachment: Attachment) =>
                                                        attachment.type ===
                                                        documentTypes.AddressReceipt,
                                                )
                                                .map(
                                                    (
                                                        file: Attachment,
                                                        index: number,
                                                    ) => (
                                                        <Nav.Item key={index}>
                                                            <Nav.Link
                                                                eventKey={
                                                                    file.awsLink
                                                                }
                                                                onClick={() =>
                                                                    setDocumentPreview(
                                                                        file,
                                                                    )
                                                                }
                                                            >
                                                                Comprovante de
                                                                Endereço
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ),
                                                )}
                                    </StyledNav>
                                )}
                            </Col>

                            {documentNamingError ? (
                                <Col>
                                    <Row>
                                        <h5
                                            style={{
                                                fontSize: 17,
                                                color: 'red',
                                            }}
                                        >
                                            Erro ao carregar documentos do
                                            integrador
                                        </h5>
                                    </Row>
                                </Col>
                            ) : (
                                <Col>
                                    <Row>
                                        {!integrator.digitalContract &&
                                            !(
                                                integrator.attachments?.length >
                                                0
                                            ) && (
                                                <StyledH5 weight="normal">
                                                    Não existem documentos
                                                    cadastrados para este
                                                    integrador
                                                </StyledH5>
                                            )}

                                        {documentPreview && (
                                            <Col
                                                className="pl-3"
                                                key={documentPreview.id}
                                            >
                                                {filePreview.isLoading && (
                                                    <Spinner animation="border" />
                                                )}
                                                {!filePreview.isLoading &&
                                                    (filePreview.isError ||
                                                        !filePreview?.data) && (
                                                        <p>
                                                            Documento não
                                                            encontrado
                                                        </p>
                                                    )}

                                                {!filePreview.isLoading &&
                                                    !filePreview.isError &&
                                                    !!filePreview?.data && (
                                                        <>
                                                            {documentPreview.extension ===
                                                                '.pdf' && (
                                                                <object
                                                                    data={
                                                                        filePreview.data
                                                                    }
                                                                    type="application/pdf"
                                                                    width="95%"
                                                                    height="650"
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'flex-start',
                                                                    }}
                                                                >
                                                                    Documento
                                                                    PDF
                                                                </object>
                                                            )}
                                                            {documentPreview.extension.match(
                                                                /\.(png|jpg|jpeg|gif|webp)$/i,
                                                            ) && (
                                                                <img
                                                                    src={
                                                                        filePreview.data
                                                                    }
                                                                    alt="Documento"
                                                                    style={{
                                                                        maxWidth:
                                                                            '95%',
                                                                        height: 'auto',
                                                                        display:
                                                                            'block',
                                                                        margin: '0 auto',
                                                                    }}
                                                                />
                                                            )}
                                                            {!(
                                                                documentPreview.extension ===
                                                                    '.pdf' ||
                                                                documentPreview.extension.match(
                                                                    /\.(png|jpg|jpeg|gif|webp)$/i,
                                                                )
                                                            ) && (
                                                                <StyledDiv>
                                                                    <a
                                                                        href={downloadDocument(
                                                                            documentPreview,
                                                                        )}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer nofollow external"
                                                                    >
                                                                        {`Acessar:
                                                                    ${
                                                                        documentPreview.type &&
                                                                        Object.values(
                                                                            documentTypes,
                                                                        ).includes(
                                                                            documentPreview.type,
                                                                        )
                                                                            ? documentTypesTranslate[
                                                                                  documentPreview
                                                                                      .type
                                                                              ]
                                                                            : 'Documento'
                                                                    }`}
                                                                    </a>
                                                                </StyledDiv>
                                                            )}
                                                        </>
                                                    )}
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    )}

                    {isTabletOrMobile && (
                        <>
                            <Row className="pl-2 pr-4 mt-4">
                                <Col>
                                    <StyledH5 weight="bold">
                                        Dados da Empresa
                                    </StyledH5>

                                    <StyledH6 color={colors.lightGrey}>
                                        CNPJ
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.cnpj
                                            ? `${integrator.cnpj.slice(
                                                  0,
                                                  2,
                                              )}.${integrator.cnpj.slice(
                                                  2,
                                                  5,
                                              )}.${integrator.cnpj.slice(
                                                  5,
                                                  8,
                                              )}/${integrator.cnpj.slice(
                                                  8,
                                                  12,
                                              )}-${integrator.cnpj.slice(
                                                  12,
                                                  14,
                                              )}`
                                            : '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        RAZÃO SOCIAL
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.name?.toUpperCase() || '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        NOME FANTASIA
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.fancyName?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        INSCRIÇÃO ESTADUAL
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.ie || '-'}
                                    </StyledH6>
                                </Col>
                            </Row>

                            <Row className="pl-2 pr-4 mt-4">
                                <Col>
                                    <StyledH5 weight="bold">
                                        Dados do Administrador
                                    </StyledH5>

                                    <StyledH6 color={colors.lightGrey}>
                                        NOME COMPLETO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.partnerName?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        CPF
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.partnerDocument || '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        DATA DE NASCIMENTO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.partnerBirthDate
                                            ? `${integrator.partnerBirthDate.slice(
                                                  -2,
                                              )}/${integrator.partnerBirthDate.slice(
                                                  5,
                                                  -3,
                                              )}/${integrator.partnerBirthDate.slice(
                                                  0,
                                                  4,
                                              )}`
                                            : '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        E-MAIL
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.partnerEmail?.toLowerCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        WHATSAPP
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.partnerPhone || '-'}
                                    </StyledH6>
                                </Col>
                            </Row>

                            <Row className="pl-2 pr-4 mt-4">
                                <Col>
                                    <StyledH5 weight="bold">Endereço</StyledH5>

                                    <StyledH6 color={colors.lightGrey}>
                                        LOGRADOURO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.addressDescription?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        NÚMERO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.addressNumber?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        COMPLEMENTO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.addressComplement?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        BAIRRO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.addressNeighborhood?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        CIDADE
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.erpCityName?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        ESTADO
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.erpState?.toUpperCase() ||
                                            '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        CEP
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.addressCep
                                            ? `${integrator.addressCep.slice(
                                                  0,
                                                  5,
                                              )}-${integrator.addressCep?.slice(
                                                  5,
                                                  8,
                                              )}`
                                            : '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        TELEFONE
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.phone
                                            ? `(${integrator.phone?.slice(
                                                  0,
                                                  2,
                                              )}) ${integrator.phone?.slice(
                                                  2,
                                                  -4,
                                              )}-${integrator.phone?.slice(-4)}`
                                            : '-'}
                                    </StyledH6>

                                    <StyledH6 color={colors.lightGrey}>
                                        E-MAIL
                                    </StyledH6>
                                    <StyledH6 color={colors.darkGrey}>
                                        {integrator.email?.toLowerCase() || '-'}
                                    </StyledH6>
                                </Col>
                            </Row>

                            <Row className="pl-2 pr-4 mt-4">
                                <Col>
                                    <StyledH5 weight="bold">
                                        Documentação
                                    </StyledH5>
                                    {!documentNamingError && (
                                        <StyledNav
                                            variant="tabs"
                                            activeKey={tab}
                                            onSelect={(selectedKey: string) =>
                                                setTab(String(selectedKey))
                                            }
                                            style={{ marginLeft: '-15px' }}
                                        >
                                            {integrator.digitalContract && (
                                                <Nav.Item
                                                    key={
                                                        documentTypes.TermsAndConditions
                                                    }
                                                >
                                                    <Nav.Link
                                                        eventKey={
                                                            integrator
                                                                .digitalContract
                                                                .name
                                                        }
                                                        onClick={() =>
                                                            setDocumentPreview(
                                                                integrator.digitalContract,
                                                            )
                                                        }
                                                    >
                                                        Termos e Condições
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )}

                                            {integrator.attachments?.length >
                                                0 &&
                                                integrator.attachments
                                                    .filter(
                                                        (
                                                            attachment: Attachment,
                                                        ) =>
                                                            attachment.type ===
                                                            documentTypes.CpfRg,
                                                    )
                                                    .map(
                                                        (
                                                            file: Attachment,
                                                            index: number,
                                                        ) => (
                                                            <Nav.Item
                                                                key={index}
                                                            >
                                                                <Nav.Link
                                                                    eventKey={
                                                                        file.awsLink
                                                                    }
                                                                    onClick={() =>
                                                                        setDocumentPreview(
                                                                            file,
                                                                        )
                                                                    }
                                                                >
                                                                    CPF e RG
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ),
                                                    )}

                                            {integrator.attachments?.length >
                                                0 &&
                                                integrator.attachments
                                                    .filter(
                                                        (
                                                            attachment: Attachment,
                                                        ) =>
                                                            attachment.type ===
                                                            documentTypes.SocialContract,
                                                    )
                                                    .map(
                                                        (
                                                            file: Attachment,
                                                            index: number,
                                                        ) => (
                                                            <Nav.Item
                                                                key={index}
                                                            >
                                                                <Nav.Link
                                                                    eventKey={
                                                                        file.awsLink
                                                                    }
                                                                    onClick={() =>
                                                                        setDocumentPreview(
                                                                            file,
                                                                        )
                                                                    }
                                                                >
                                                                    Contrato
                                                                    Social
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ),
                                                    )}

                                            {integrator.attachments?.length >
                                                0 &&
                                                integrator.attachments
                                                    .filter(
                                                        (
                                                            attachment: Attachment,
                                                        ) =>
                                                            attachment.type ===
                                                            documentTypes.AddressReceipt,
                                                    )
                                                    .map(
                                                        (
                                                            file: Attachment,
                                                            index: number,
                                                        ) => (
                                                            <Nav.Item
                                                                key={index}
                                                            >
                                                                <Nav.Link
                                                                    eventKey={
                                                                        file.awsLink
                                                                    }
                                                                    onClick={() =>
                                                                        setDocumentPreview(
                                                                            file,
                                                                        )
                                                                    }
                                                                >
                                                                    Comprovante
                                                                    de Endereço
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ),
                                                    )}
                                        </StyledNav>
                                    )}
                                </Col>
                            </Row>

                            {documentNamingError ? (
                                <Row className="pl-2 pr-4 mt-4">
                                    <Col className="pl-3">
                                        <h5
                                            style={{
                                                fontSize: 17,
                                                color: 'red',
                                            }}
                                        >
                                            Erro ao carregar documentos do
                                            integrador
                                        </h5>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="pl-2 pr-4 mt-4">
                                    {!integrator.digitalContract &&
                                        !(
                                            integrator.attachments?.length > 0
                                        ) && (
                                            <Col className="pl-3">
                                                <StyledH5 weight="normal">
                                                    Não existem documentos
                                                    cadastrados para este
                                                    integrador
                                                </StyledH5>
                                            </Col>
                                        )}

                                    {documentPreview && (
                                        <Col
                                            className="pl-3"
                                            key={documentPreview.id}
                                        >
                                            {filePreview.isLoading && (
                                                <Spinner animation="border" />
                                            )}
                                            {!filePreview.isLoading &&
                                                (filePreview.isError ||
                                                    !filePreview?.data) && (
                                                    <p>
                                                        Documento não encontrado
                                                    </p>
                                                )}

                                            {!filePreview.isLoading &&
                                                !filePreview.isError &&
                                                !!filePreview?.data && (
                                                    <>
                                                        {documentPreview.extension ===
                                                            '.pdf' && (
                                                            <object
                                                                data={
                                                                    filePreview.data
                                                                }
                                                                type="application/pdf"
                                                                width="95%"
                                                                height="650"
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'flex-start',
                                                                }}
                                                            >
                                                                Documento PDF
                                                            </object>
                                                        )}
                                                        {documentPreview.extension.match(
                                                            /\.(png|jpg|jpeg|gif|webp)$/i,
                                                        ) && (
                                                            <img
                                                                src={
                                                                    filePreview.data
                                                                }
                                                                alt="Documento"
                                                                style={{
                                                                    maxWidth:
                                                                        '95%',
                                                                    height: 'auto',
                                                                    display:
                                                                        'block',
                                                                    margin: '0 auto',
                                                                }}
                                                            />
                                                        )}
                                                        {!(
                                                            documentPreview.extension ===
                                                                '.pdf' ||
                                                            documentPreview.extension.match(
                                                                /\.(png|jpg|jpeg|gif|webp)$/i,
                                                            )
                                                        ) && (
                                                            <StyledDiv>
                                                                <a
                                                                    href={downloadDocument(
                                                                        documentPreview,
                                                                    )}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer nofollow external"
                                                                >
                                                                    {`Acessar:
                                                                    ${
                                                                        documentPreview.type &&
                                                                        Object.values(
                                                                            documentTypes,
                                                                        ).includes(
                                                                            documentPreview.type,
                                                                        )
                                                                            ? documentTypesTranslate[
                                                                                  documentPreview
                                                                                      .type
                                                                              ]
                                                                            : 'Documento'
                                                                    }`}
                                                                </a>
                                                            </StyledDiv>
                                                        )}
                                                    </>
                                                )}
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </>
                    )}
                </>
            )}

            {showApproveIntegratorModal && (
                <ConfirmationDialog
                    show={showApproveIntegratorModal}
                    onHide={() => setShowApproveIntegratorModal(false)}
                    onConfirm={async () =>
                        handleStatusChange(sellerStatus.Approved)
                    }
                    icon={NotificationIcon.Info}
                    title="Aprovar cadastro"
                    text={`Após avaliar as informações, deseja\nrealmente APROVAR o cadastro do integrador?\n\nESTA AÇÃO NÃO PODE SER DESFEITA`}
                />
            )}

            {showReprovalObservationModal && (
                <ConfirmationDialog
                    show={showReprovalObservationModal}
                    onHide={() => setShowReprovalObservationModal(false)}
                    onConfirmWithFeedback={async (feedback: string) =>
                        handleStatusChange(sellerStatus.Reproved, feedback)
                    }
                    required
                    icon={NotificationIcon.Warning}
                    title="Reprovar cadastro"
                    text="Deseja adicionar o motivo da reprovação?"
                    placeholder="Insira aqui a justificativa para reprovação do cadastro do integrador"
                />
            )}
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { View };
