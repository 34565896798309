/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Highlighter from 'react-highlight-words';
import BaseLayout from '../../components/BaseLayout';
import { StyledTh } from './styles';
import CustomerHttpService from '../../services/http/customer-http';
import { New } from './New';
import { rowsPerPage } from '../../constants/pagination';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import formatDate from '../../utils/formatDate';

const List: React.FC = () => {
    const [data, setData] = useState([] as any);
    const [term, setTerm] = useState('');
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [page, setPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onLoadMore: () => setPage((p) => (p += 1)),
    });

    const loadData = async (clear?: boolean) => {
        setLoading(true);

        const params = {
            term,
            skip: page,
            take: rowsPerPage,
        };

        try {
            const response: any = await CustomerHttpService.readMany(params);

            setHasNextPage(response.data.length === rowsPerPage);
            if (clear) {
                setData(response.data);
            }

            setData((prevData: any) => [...prevData, ...response.data]);

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setHasNextPage(false);
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setData([]);
                setPage(1);
                loadData();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadData();
        }
    }, [page]);

    useEffect(() => {
        loadData();

        setIsFirstRender(false);
    }, []);

    function handleUpdate() {
        setData([]);
        loadData(true);
        setPage(1);
    }

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Clientes</StyledPageTitle>
                    <StyledPageSubTitle>
                        {' '}
                        Todas as informações de seus clientes em um só lugar.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button
                        onClick={() => {
                            setSelectedId('');
                            setShowModal(true);
                        }}
                    >
                        <i className="fas fa-plus" /> Novo Cliente
                    </Button>
                </Col>
            </Row>

            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event: any) =>
                                setTerm(event.target.value)
                            }
                            placeholder="Digite aqui o nome do cliente que procura"
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="pl-2 pr-2">
                <Col>
                    <Table
                        ref={infiniteRef}
                        bordered
                        hover
                        size="sm"
                        className="text-center"
                    >
                        <thead>
                            <tr>
                                <StyledTh>CADASTRADO EM</StyledTh>
                                <StyledTh>CLIENTE</StyledTh>
                                <StyledTh>E-MAIL</StyledTh>
                                <StyledTh>TELEFONE</StyledTh>
                                <StyledTh>CIDADE/UF</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>{formatDate(item.createdAt)}</td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={item.name}
                                            />
                                        </td>
                                        <td>
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={item.email}
                                            />
                                        </td>
                                        <td>
                                            ({item.phoneCode}) {item.phone}
                                        </td>
                                        <td>{`${item.erpCityName}/${item.erpState}`}</td>
                                        <td>
                                            <Dropdown key="left">
                                                <Dropdown.Toggle
                                                    bsPrefix="nexen"
                                                    as={Button}
                                                    variant="text"
                                                >
                                                    <ReactMoreIcon
                                                        fill="#bdbdbd"
                                                        width="10"
                                                        height="20"
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelectedId(
                                                                item.id,
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        Editar
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Col>

                {loading && hasNextPage && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!hasNextPage && !loading && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            <New
                id={selectedId}
                show={showModal}
                onHide={() => {
                    setSelectedId('');
                    setShowModal(false);
                }}
                handleUpdate={() => handleUpdate()}
            />
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { List };
