import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import BaseLayout from '../../components/BaseLayout';
import UserHttpService from '../../services/http/user-http';
import PaymentMethodHttpService from '../../services/http/payment-http';
import { userListRoute } from '../../routes/config';
import history from '../../services/history';
import RoleHttpService from '../../services/http/role-http';
import { userRules, userRulesWithCNPJ } from '../../validations/integrator';
import getValidationsErrors from '../../utils/getValidationsErrors';
import { StyledH4, StyledLink, StyledNav } from './styles';
import RegionHttpService from '../../services/http/region-http';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import NotificationMethods from '../../constants/notificationMethods';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import Role from '../../constants/roles';
import UserMaxDiscounts, {
    Discount,
} from '../../components/User/UserMaxDiscounts';
import UserInfo from '../../components/User/UserInfo';
import RoleType from '../../constants/roleType';

export interface OptionType {
    label: string;
    value: number | string;
    isFixed?: boolean;
}

const New: React.FC = () => {
    const newUser = () => ({
        id: '',
        username: '',
        email: '',
        password: '',
        cpf: '',
        password_confirmation: '',
        cnpj: '',
        roles: [],
        regions: [],
        max_percentage_discount: '',
        notificationMethods: [],
        userMaxDiscounts: [],
        canViewMarginLiquid: false,
    });

    const newDiscount: Discount = {
        installment: '',
        value: 0,
    };

    const { id } = useParams<{ id: string }>();
    const formRef: any = useRef<FormHandles>(null);
    const [errors, setErrors] = useState(newUser());
    const [user, setUser] = useState(newUser());
    const [roles, setRoles] = useState<OptionType[]>([]);
    const [regions, setRegions] = useState<OptionType[]>([]);
    const [userRegions, setUserRegions] = useState<OptionType[]>([]);
    const [userRoles, setUserRoles] = useState<OptionType[]>([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userMaxDiscounts, setUserMaxDiscounts] = useState<Discount[]>([
        newDiscount,
    ]);
    const [notificationMethodsList, setNotificationMethodsList] = useState<
        OptionType[]
    >([]);
    const [notificationMethods, setNotificationMethods] = useState<
        OptionType[]
    >([
        {
            label: 'Aplicação',
            value: NotificationMethods.Aplicacao,
            isFixed: true,
        },
    ] as any);
    const [tab, setTab] = useState('infos');

    useEffect(() => {
        setNotificationMethodsList([
            ...notificationMethods,
            ...[
                {
                    label: 'E-mail',
                    value: NotificationMethods.Email,
                    isFixed: false,
                },
            ],
        ]);

        const loadRoles = async () => {
            const results = await RoleHttpService.readMany({
                roleType: RoleType.RegularUserRoles,
            });

            setRoles(
                results.data.data
                    .filter((role: any) => role.reference !== Role.Integrator)
                    .map((role: any) => ({
                        value: role.id,
                        label: role.name,
                        reference: role.reference,
                    })),
            );
        };

        loadRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const loadRegions = async () => {
            const results = await RegionHttpService.readMany({
                term: '',
                all: true,
            });

            setRegions(
                results.data.data.map((region: any) => ({
                    label: region.name,
                    value: region.id,
                    states: region.states,
                })),
            );
        };

        loadRegions();
    }, []);

    useEffect(() => {
        const loadPaymentMethods = async () => {
            const { data } =
                await PaymentMethodHttpService.listPaymentMethods();

            setPaymentMethods(data.data);
        };

        loadPaymentMethods();
    }, []);

    useEffect(() => {
        async function loadUser(): Promise<void> {
            const response = await UserHttpService.readOne(id);
            const { data } = response;

            if (data.cnpj) {
                data.cnpj = data.cnpj.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5',
                );
            }

            setUser({
                id: data.id,
                username: data.name,
                email: data.email,
                cnpj: data.cnpj,
                cpf: data.cpf,
                password: '',
                password_confirmation: '',
                roles: [],
                regions: [],
                max_percentage_discount: data.maxPercentageDiscount,
                notificationMethods: [],
                userMaxDiscounts: data.userMaxDiscounts,
                canViewMarginLiquid: data.canViewMarginLiquid,
            });

            const mappedRoles = data.roles.map((item: any) => ({
                value: item.id,
                label: item.name,
                reference: item.reference,
            }));

            const mappedUserMaxDiscounts = data.discounts.map((item: any) => ({
                id: item.id,
                method: item.method,
                value: item.value,
                installment: item.installment,
            }));

            const mappedRegions = data.regions.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));

            const splittedNotificationMethods =
                data.notificationMethods.split(',');

            const newNoti = splittedNotificationMethods.map((item: any) => {
                let label = '';
                let value = '';
                let isFixed = false;

                if (item.trim() === NotificationMethods.Aplicacao) {
                    label = 'Aplicação';
                    value = NotificationMethods.Aplicacao;
                    isFixed = true;
                }

                if (item.trim() === NotificationMethods.Email) {
                    label = 'E-mail';
                    value = NotificationMethods.Email;
                    isFixed = false;
                }

                return {
                    value,
                    label,
                    isFixed,
                };
            });

            setUserMaxDiscounts(mappedUserMaxDiscounts);
            setUserRegions(mappedRegions);
            setUserRoles(mappedRoles);
            setNotificationMethods(newNoti);
        }

        if (id) {
            loadUser();
        }
    }, [id]);

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            if (item.target.name === 'canViewMarginLiquid') {
                changes.canViewMarginLiquid = !user.canViewMarginLiquid;
                newErrors.canViewMarginLiquid = undefined;

                return;
            }

            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });

        setUser({ ...user, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const handleChangesNotificationMethods = (
        value: any,
        { action, removedValue }: any,
    ) => {
        if (action === 'pop-value' && removedValue.isFixed) {
            return;
        }

        let newNotificationMethods = value;

        if (action === 'clear') {
            newNotificationMethods = notificationMethods.filter(
                (v: any) => v.isFixed,
            );
        }

        setNotificationMethods(newNotificationMethods);
    };

    const handleDiscountChanges = (e: any, method: string, index: number) => {
        if (e.target.value > 100 || e.target.value < 0) {
            return;
        }

        userMaxDiscounts.filter((i: any) => i.method === method)[index].value =
            e.target.value;

        setUser({ ...user });
    };

    const handleInstallmentChanges = (
        e: any,
        method: string,
        index: number,
    ) => {
        userMaxDiscounts.filter((i: any) => i.method === method)[
            index
        ].installment = e.value;

        setUser({ ...user });
    };

    const prepareData = (data: any) => {
        const userMaxDiscountsFiltered = userMaxDiscounts.filter(
            (discount) =>
                discount.installment !== 'Informe o Nº de parcelas...' &&
                discount.installment !== '',
        );

        const discounts = userMaxDiscountsFiltered.map((discount) => ({
            ...(discount.id && { id: discount.id }),
            method: discount.method,
            installment: discount.installment,
            value: Number(discount.value),
        }));

        const userData = {
            id: data.id,
            name: data.username,
            email: data.email,
            cpf: data.cpf ? data.cpf.replace(/\D/g, '') : undefined,
            cnpj: data.cnpj ? data.cnpj.replace(/\D/g, '') : undefined,
            password: data.password ? data.password : undefined,
            roles: userRoles?.map((item: any) => item.reference),
            regions: userRegions?.map((item: any) => item.value),
            notificationMethods: notificationMethods?.map(
                (item: any) => item.value,
            ),
            discounts,
            canViewMarginLiquid: data.canViewMarginLiquid,
        };

        return userData;
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        try {
            let schema = null;
            (user as any).roles = userRoles as unknown as any[];
            if (
                userRoles.find((role: any) =>
                    role.label.includes('Transportadora'),
                )
            ) {
                schema = Yup.object().shape(userRulesWithCNPJ);
            } else {
                schema = Yup.object().shape(userRules);
            }

            await schema.validate(user, { abortEarly: false, context: user });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            return;
        }

        try {
            setLoading(true);

            const data = prepareData(user);

            if (user.id) {
                await UserHttpService.update(user.id, data);
            } else {
                await UserHttpService.create(data);
            }

            history.push(userListRoute.path);
            toast.success('Usuário salvo com sucesso!');
        } catch (err) {
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Erro ao salvar dados do usuário!');
            return;
        } finally {
            setLoading(false);
        }
    };

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Usuários</StyledPageTitle>
                    <StyledPageSubTitle>
                        Todas as informações dos usuários em um só lugar.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button disabled={loading} onClick={handleSubmit}>
                        {loading && <Spinner size="sm" animation="border" />}
                        {id
                            ? 'Alterar informações do usuário'
                            : 'Cadastrar usuário'}
                    </Button>
                </Col>
            </Row>

            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <StyledH4>
                        <StyledLink to={userListRoute.path}>
                            <i className="fas fa-chevron-left mr-2" />
                            {user.username ? user.username : 'Cadastro'}
                        </StyledLink>
                    </StyledH4>
                    {/* <p>Informações sobre o usuário</p> */}
                </Col>
            </Row>

            <Row className="mt-4 pl-2 pr-2">
                <Col>
                    <StyledNav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={(selectedKey: string) =>
                            setTab(`${selectedKey}`)
                        }
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="infos">
                                Informações sobre o usuário
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="discounts">
                                Percentuais de Desconto
                            </Nav.Link>
                        </Nav.Item>
                    </StyledNav>
                </Col>
            </Row>

            <Form ref={formRef} onSubmit={handleSubmit}>
                {tab === 'infos' && (
                    <>
                        <UserInfo
                            user={user}
                            roles={roles}
                            errors={errors}
                            regions={regions}
                            userRoles={userRoles}
                            userRegions={userRegions}
                            setUserRoles={setUserRoles}
                            handleChanges={handleChanges}
                            setUserRegions={setUserRegions}
                            notificationMethods={notificationMethods}
                            notificationMethodsList={notificationMethodsList}
                            handleChangesNotificationMethods={
                                handleChangesNotificationMethods
                            }
                        />
                    </>
                )}
                {tab === 'discounts' && (
                    <>
                        <UserMaxDiscounts
                            paymentMethods={paymentMethods}
                            userMaxDiscounts={userMaxDiscounts}
                            setUserMaxDiscounts={setUserMaxDiscounts}
                            handleDiscountChanges={handleDiscountChanges}
                            handleInstallmentChanges={handleInstallmentChanges}
                        />
                    </>
                )}
            </Form>
        </BaseLayout>
    );
};

export { New };
