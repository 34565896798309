import React, { useState } from 'react';
import { Buffer } from 'buffer';
import {
    Col,
    Image,
    NavDropdown,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ReactLogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as ReactProfileIcon } from '../../../assets/icons/profile.svg';
import NotificationIcon from '../../../constants/notificationIcon';
import Role from '../../../constants/roles';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { companyProfileRoute, userProfileRoute } from '../../../routes/config';
import LoginService from '../../../services/login';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import getRolesNames from '../../../utils/getRolesNames';
import userHasRoles from '../../../utils/userHasRoles';
import ChangeAvatarModal from '../../Profile/ChangeAvatarModal';
import {
    StyledDropdownButton,
    StyledDropdownSubtitle,
    StyledDropdownTitle,
    StyledNavDropdownItem,
    StyledNavDropdownItemText,
    StyledTextRole,
} from '../styles';

const UserDropdownMenu: React.FC = () => {
    const { user }: AuthValues = useAuth();
    const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);

    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

    const isIntegrator = userHasRoles(user, [Role.Integrator]);

    const history = useHistory();

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    const logout = async () => {
        await LoginService.logout();
    };

    return (
        <>
            <StyledDropdownButton
                className="mr-1"
                size="lg"
                menuAlign={{ lg: 'right' }}
                title={
                    <>
                        {avatar ? (
                            <Image
                                className="mt-n2 mr-n3 avatar-border"
                                width="48"
                                src={avatar}
                                roundedCircle
                            />
                        ) : (
                            <ReactProfileIcon
                                className="mt-n1 mr-n3"
                                width={38}
                                height={38}
                                fill="#ffffff"
                            />
                        )}
                    </>
                }
            >
                <NavDropdown.Header style={{ width: '400px' }}>
                    <Row className="align-items-center ma-0 pa-0">
                        <Col md={2} className="text-left">
                            <OverlayTrigger
                                key="left"
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        Altere seu avatar
                                    </Tooltip>
                                }
                            >
                                {avatar ? (
                                    <Image
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setShowChangeAvatarModal(true)
                                        }
                                        width="60"
                                        src={avatar}
                                        roundedCircle
                                    />
                                ) : (
                                    <ReactProfileIcon
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setShowChangeAvatarModal(true)
                                        }
                                        width={60}
                                        height={60}
                                        fill="#cccccc"
                                    />
                                )}
                            </OverlayTrigger>
                        </Col>
                        <Col md={10}>
                            <StyledDropdownTitle className="d-block text-wrap pl-2">
                                {user.name}
                            </StyledDropdownTitle>
                            <StyledDropdownSubtitle className="d-block text-wrap pl-2">
                                {user.email}
                            </StyledDropdownSubtitle>
                            <StyledTextRole className="text-wrap pl-2">
                                {getRolesNames(user.roles)}
                            </StyledTextRole>
                        </Col>
                    </Row>
                </NavDropdown.Header>

                <NavDropdown.Divider />

                <StyledNavDropdownItemText>
                    Configurações
                </StyledNavDropdownItemText>

                <StyledNavDropdownItem
                    onClick={() => history.push(userProfileRoute.path)}
                >
                    Meu perfil
                </StyledNavDropdownItem>

                {isIntegrator && (
                    <StyledNavDropdownItem
                        onClick={() => history.push(companyProfileRoute.path)}
                    >
                        Minha empresa
                    </StyledNavDropdownItem>
                )}

                <NavDropdown.Divider />

                <StyledNavDropdownItem
                    onClick={() => {
                        setShowLogoutModal(true);
                    }}
                >
                    <ReactLogoutIcon
                        width={20}
                        height={20}
                        fill="#707070"
                        className="mr-2"
                    />
                    Sair
                </StyledNavDropdownItem>
            </StyledDropdownButton>

            <ChangeAvatarModal
                show={showChangeAvatarModal}
                onHide={() => {
                    setShowChangeAvatarModal(false);
                }}
            />

            {showLogoutModal && (
                <ConfirmationDialog
                    show={showLogoutModal}
                    onHide={() => setShowLogoutModal(false)}
                    onConfirm={logout}
                    icon={NotificationIcon.Logout}
                    title="Deseja realmente sair?"
                    text="Você irá sair do sistema"
                />
            )}
        </>
    );
};

export default UserDropdownMenu;
