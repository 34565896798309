import { Button, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import { useInfiniteQuery, useMutation } from 'react-query';
import Highlighter from 'react-highlight-words';
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledTh } from '../styles';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';

import NotificationIcon from '../../../../constants/notificationIcon';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import IModuleCubage from '../../../../shared/interfaces/module-cubage.interface';
import ModulesCubageModal from './ModulesModal';
import handleResponseError from '../../../../utils/handleResponseError';
import CubageHttpService, {
    ListCubageParams,
} from '../../../../services/http/cubage.http';
import ProductGroup from '../../../../constants/productGroup';
import ModulesFilterSection from './FilterSection';

export interface ModulesFilterProps {
    filterTerm: string;
}

export default function ModulesCubageTable() {
    const rowsPerPage = 20;
    const [showAddModulesModal, setShowAddModulesModal] =
        useState<boolean>(false);
    const [showRemoveModulesModal, setShowRemoveModulesModal] =
        useState<boolean>(false);
    const [selectedModule, setSelectedModule] = useState<IModuleCubage | null>(
        null,
    );

    const form = useForm<ModulesFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
        },
    });

    const formData = form.watch();
    const termTrimmed = formData.filterTerm.trim();

    const ModulesQuery = useInfiniteQuery({
        queryKey: ['modules-cubage', termTrimmed],
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async ({ pageParam = 0 }) => {
            const params: ListCubageParams = {
                skip: pageParam,
                page: pageParam + 1,
                take: rowsPerPage,
                term: termTrimmed || '',
            };

            const response = await CubageHttpService.readMany(
                ProductGroup.ModulesCubage,
                params,
            );

            return {
                data: response.data.data,
                currentPage: pageParam,
                pages: Math.ceil(response.data.total / rowsPerPage),
            };
        },
        getNextPageParam: ({ currentPage, pages }) =>
            currentPage + 1 < pages ? currentPage + 1 : null,
        onError: (err) => {
            handleResponseError(err, 'Erro ao buscar módulos');
        },
    });

    const target = useRef(null);

    useIntersectionObserver({
        target,
        onIntersect: ModulesQuery.fetchNextPage,
        enabled: ModulesQuery.hasNextPage,
    });

    const showLoading =
        ModulesQuery.isLoading ||
        ModulesQuery.isFetchingNextPage ||
        !ModulesQuery.isFetched;

    const deleteMutation = useMutation(
        async () => {
            if (!selectedModule?.id) {
                throw new Error('Nenhum módulo selecionado para exclusão');
            }

            const response = await CubageHttpService.delete(
                ProductGroup.ModulesCubage,
                selectedModule?.id,
            );

            return response.data;
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao excluir módulo');
            },
            onSuccess: async () => {
                toast.success('Módulo removido com sucesso');

                ModulesQuery.refetch();
            },
        },
    );

    const data = ModulesQuery.data?.pages.reduce(
        (accumulator, current) => accumulator.concat(current.data),
        [] as IModuleCubage[],
    );

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Cubagem dos módulos
                    </StyledPageTitle>
                </Col>
                <Button
                    className="float-right w-20 m-2"
                    style={{ width: '180px' }}
                    onClick={() => {
                        setShowAddModulesModal(true);
                    }}
                >
                    Nova configuração
                </Button>
            </Row>
            <ModulesFilterSection control={form.control} />
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Código ERP</StyledTh>
                            <StyledTh>Quantidade módulos para cubagem</StyledTh>
                            <StyledTh>Cubagem</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((moduleCubage: IModuleCubage) => (
                                <tr key={moduleCubage.id}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={moduleCubage?.id.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={moduleCubage.productErpCode.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={moduleCubage.modulesForCubage.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={moduleCubage.cubage.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedModule(
                                                            moduleCubage,
                                                        );

                                                        setShowAddModulesModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedModule(
                                                            moduleCubage,
                                                        );

                                                        setShowRemoveModulesModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>
            <Row className="mx-4" ref={target}>
                <br />
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {data?.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showAddModulesModal && (
                <ModulesCubageModal
                    selectedModule={selectedModule}
                    show={showAddModulesModal}
                    refetch={ModulesQuery.refetch}
                    onHide={() => {
                        setSelectedModule(null);
                        setShowAddModulesModal(false);
                    }}
                />
            )}
            {showRemoveModulesModal && (
                <ConfirmationDialog
                    show={showRemoveModulesModal}
                    onHide={() => {
                        setShowRemoveModulesModal(false);
                        setSelectedModule(null);
                        ModulesQuery.refetch();
                    }}
                    onConfirm={async () => deleteMutation.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir Configuração de Cubagem"
                    text="Deseja excluir a configuração de cubagem selecionada?"
                />
            )}
        </>
    );
}
