/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import { Buffer } from 'buffer';
import { Accordion, Card, Image } from 'react-bootstrap';
import { FaSolarPanel } from 'react-icons/fa6';
import { LiaUserTieSolid } from 'react-icons/lia';
import {
    PiBooks,
    PiCaretDown,
    PiCaretLeftBold,
    PiCube,
    PiGear,
    PiHandshake,
    PiHouse,
    PiMapPin,
    PiQuestion,
    PiSignOut,
    PiTruck,
    PiUser,
    PiUsers,
    PiUsersThree,
    PiPercent,
} from 'react-icons/pi';
import { useHistory } from 'react-router-dom';
import userIcon from '../../assets/icons/user.svg';
import NotificationIcon from '../../constants/notificationIcon';
import pages from '../../constants/pages';
import Role from '../../constants/roles';
import sellerStatus from '../../constants/sellerStatus';
import { useAuth } from '../../contexts/authContext';
import { dashboardListRoute, userProfileRoute } from '../../routes/config';
import { documentsRoute } from '../../routes/config/documents.route';
import LoginService from '../../services/login';
import MenuService, { Option, Suboption } from '../../services/menu';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import userHasRoles from '../../utils/userHasRoles';
import {
    StyledCardBody,
    StyledCardHeader,
    StyledContentDiv,
    StyledFillerDiv,
    StyledHeaderDiv,
    StyledListDiv,
    StyledMainDiv,
    StyledOptionDiv,
    StyledUserDiv,
    StyledUsernameDiv,
} from './styles';

interface Props {
    onHide: () => void;
}

const Sidebar = (props: Props) => {
    const { onHide } = props;
    const { user } = useAuth();

    const history = useHistory();
    const componentIsMounted = useRef(true);

    const [menu, setMenu] = useState(MenuService.get(user));

    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

    const userAllowedPages = pages.filter((page) =>
        page.roles.length > 0 ? userHasRoles(user, page.roles) : true,
    );

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    const getIcon = (iconName: string) => {
        if (iconName === 'PiHouse') {
            return <PiHouse />;
        }

        if (iconName === 'LiaUserTieSolid') {
            return <LiaUserTieSolid />;
        }

        if (iconName === 'PiMapPin') {
            return <PiMapPin />;
        }

        if (iconName === 'PiUser') {
            return <PiUser />;
        }

        if (iconName === 'PiUsersThree') {
            return <PiUsersThree />;
        }

        if (iconName === 'PiHandshake') {
            return <PiHandshake />;
        }

        if (iconName === 'PiUsers') {
            return <PiUsers />;
        }

        if (iconName === 'FaSolarPanel') {
            return <FaSolarPanel />;
        }

        if (iconName === 'PiGear') {
            return <PiGear />;
        }

        if (iconName === 'PiTruck') {
            return <PiTruck />;
        }

        if (iconName === 'PiBooks') {
            return <PiBooks />;
        }

        if (iconName === 'PiPercent') {
            return <PiPercent />;
        }

        return <PiQuestion />;
    };

    const goToPath = (path: string) => {
        history.push(path);
        onHide();
    };

    const logout = async () => {
        await LoginService.logout();
    };

    useEffect(() => {
        if (!componentIsMounted.current) {
            const menuOptions = MenuService.get(user);

            setMenu(menuOptions);
        }
    }, [user]);

    useEffect(() => {
        componentIsMounted.current = false;
    });

    return (
        <StyledMainDiv>
            <StyledContentDiv>
                <StyledHeaderDiv>
                    <span
                        onClick={() =>
                            goToPath(
                                userHasRoles(user, [Role.Integrator]) &&
                                    user?.seller?.status !==
                                        sellerStatus.Approved
                                    ? documentsRoute.path
                                    : dashboardListRoute.path,
                            )
                        }
                    >
                        nexen
                    </span>
                    <PiCaretLeftBold onClick={onHide} />
                </StyledHeaderDiv>

                <StyledUserDiv>
                    <Image
                        className="avatar-border"
                        src={avatar || userIcon}
                        height="63px"
                        roundedCircle
                        onClick={() => goToPath(userProfileRoute.path)}
                        style={{ borderColor: '#545454' }}
                    />
                    <StyledUsernameDiv>
                        <p onClick={() => goToPath(userProfileRoute.path)}>
                            <span>{user.name}</span>
                        </p>
                        <p>
                            <span>{user.email}</span>
                        </p>
                    </StyledUsernameDiv>
                </StyledUserDiv>

                <hr />

                {((userHasRoles(user, [Role.Integrator]) &&
                    user?.seller?.status === sellerStatus.Approved) ||
                    !userHasRoles(user, [Role.Integrator])) && (
                    <>
                        <Accordion>
                            <Card style={{ border: 'none' }}>
                                <Accordion.Toggle
                                    as={StyledCardHeader}
                                    variant="link"
                                    eventKey="0"
                                >
                                    <PiCube size={24} />
                                    <span>Módulos</span>
                                    <PiCaretDown
                                        size={24}
                                        style={{ marginLeft: 'auto' }}
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <StyledCardBody>
                                        {userAllowedPages.map(
                                            (page: any, index: number) => (
                                                <a
                                                    key={index}
                                                    href={page.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {getIcon(page.piIcon)}
                                                    <span>{page.name}</span>
                                                </a>
                                            ),
                                        )}
                                    </StyledCardBody>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <hr />
                    </>
                )}

                <StyledListDiv>
                    {menu.map((option: Option, index: number) =>
                        option.suboptions ? (
                            <Accordion key={index}>
                                <Card
                                    style={{
                                        border: 'none',
                                        background: 'transparent',
                                    }}
                                >
                                    <Accordion.Toggle
                                        as={StyledOptionDiv}
                                        variant="link"
                                        eventKey="0"
                                    >
                                        {getIcon(option.sidebarIcon)}
                                        <span>{option.text}</span>
                                        <PiCaretDown
                                            size={24}
                                            style={{ marginLeft: 'auto' }}
                                        />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <StyledCardBody>
                                            {option.suboptions.map(
                                                (
                                                    suboption: Suboption,
                                                    suboptionIndex: number,
                                                ) => (
                                                    <a
                                                        href={suboption.path}
                                                        key={suboptionIndex}
                                                    >
                                                        {getIcon(
                                                            suboption.sidebarIcon,
                                                        )}
                                                        <span>
                                                            {suboption.text}
                                                        </span>
                                                    </a>
                                                ),
                                            )}
                                        </StyledCardBody>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        ) : (
                            <StyledOptionDiv
                                key={index}
                                onClick={() => goToPath(option.path ?? '')}
                            >
                                {getIcon(option.sidebarIcon)}
                                <span>{option.text}</span>
                            </StyledOptionDiv>
                        ),
                    )}
                </StyledListDiv>

                <StyledOptionDiv
                    onClick={async () => {
                        setShowLogoutModal(true);
                    }}
                    style={{ marginTop: 'auto' }}
                >
                    <PiSignOut />
                    <span>Sair</span>
                </StyledOptionDiv>
            </StyledContentDiv>

            <StyledFillerDiv onClick={onHide} />

            {showLogoutModal && (
                <ConfirmationDialog
                    show={showLogoutModal}
                    onHide={() => setShowLogoutModal(false)}
                    onConfirm={logout}
                    icon={NotificationIcon.Logout}
                    title="Deseja realmente sair?"
                    text="Você irá sair do sistema"
                />
            )}
        </StyledMainDiv>
    );
};

export default Sidebar;
