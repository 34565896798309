import React, { useState, useEffect } from 'react';
import { Col, Form, Nav, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import BaseLayout from '../../components/BaseLayout';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledNav } from '../../styles/nav';
import PointData from '../../components/Configuration/PointData';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import BannerTable from '../../components/Configuration/BannerData/BannerTable';
import OnlineStoreData from '../../components/Configuration/OnlineStoreData';
import Role from '../../constants/roles';
import userHasRoles from '../../utils/userHasRoles';
import { AuthValues, useAuth } from '../../contexts/authContext';
import BranchesData from '../../components/Configuration/BranchesData';
import ContactsData from '../../components/Configuration/ContactsData';
import ReturnUsers from '../../components/Configuration/ReturnUsers';
import { SalesCampaign } from '../../components/Configuration/SalesCampaignData';
import InsuranceData from '../../components/Configuration/InsuranceData';
import CubageData from '../../components/Configuration/CubageData';

const List: React.FC = () => {
    const { user }: AuthValues = useAuth();
    const isAdministrator = userHasRoles(user, [Role.Administrator]);
    const isTechSupport = userHasRoles(user, [Role.TechnicalSupport]);
    const isPurchase = userHasRoles(user, [Role.Purchase]);
    const isLogistics = userHasRoles(user, [Role.Logistics]);
    const pathname = window.location.pathname.split('/')[2];
    const history = useHistory();
    const [tab, setTab] = useState(pathname);
    const handleTab = (eventKey: any) => {
        setTab(eventKey);

        history.push(eventKey);
    };

    useEffect(() => {
        setTab(pathname);
    }, [pathname]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Configurações do sistema
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Todas as configurações do sistema em um só lugar.
                    </StyledPageSubTitle>
                </Col>
            </Row>

            <Row className="mt-4 pl-2 pr-2">
                <Col>
                    <StyledNav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={(selectedKey: string) =>
                            handleTab(selectedKey)
                        }
                    >
                        {!isPurchase && (
                            <>
                                <Nav.Item>
                                    <Nav.Link eventKey="banners">
                                        Banners
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="contacts">
                                        Contatos por Região
                                    </Nav.Link>
                                </Nav.Item>
                            </>
                        )}
                        {isAdministrator && (
                            <>
                                <Nav.Item>
                                    <Nav.Link eventKey="points">
                                        Pontos
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="branches">
                                        Filiais
                                    </Nav.Link>
                                </Nav.Item>
                            </>
                        )}
                        {(isAdministrator || isTechSupport || isPurchase) && (
                            <>
                                {!isPurchase && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="onlineStore">
                                            Loja Online
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </>
                        )}

                        {isAdministrator && (
                            <Nav.Item>
                                <Nav.Link eventKey="returnUsers">
                                    Usuários Devolução
                                </Nav.Link>
                            </Nav.Item>
                        )}

                        {isAdministrator && (
                            <Nav.Item>
                                <Nav.Link eventKey="sales">
                                    Campanhas Promocionais
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {isAdministrator && (
                            <Nav.Item>
                                <Nav.Link eventKey="insurance">Seguro</Nav.Link>
                            </Nav.Item>
                        )}
                        {(isAdministrator || isLogistics) && (
                            <Nav.Item>
                                <Nav.Link eventKey="cubage">Cubagem</Nav.Link>
                            </Nav.Item>
                        )}
                    </StyledNav>
                </Col>
            </Row>

            <Row className="mt-4 pl-2 pr-2">
                <Col>
                    <Form>
                        {tab === 'points' && <PointData />}
                        {tab === 'branches' && <BranchesData />}
                        {tab === 'banners' && <BannerTable />}
                        {tab === 'contacts' && <ContactsData />}
                        {tab === 'onlineStore' && <OnlineStoreData />}
                        {tab === 'returnUsers' && <ReturnUsers />}
                        {tab === 'sales' && <SalesCampaign />}
                        {tab === 'insurance' && <InsuranceData />}
                        {tab === 'cubage' && <CubageData />}
                    </Form>
                </Col>
            </Row>
        </BaseLayout>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { List };
