import { Button, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import { useInfiniteQuery, useMutation } from 'react-query';
import Highlighter from 'react-highlight-words';
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledTh } from '../styles';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';

import NotificationIcon from '../../../../constants/notificationIcon';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import StructuresCubageModal from './StructuresModal';
import CubageHttpService, {
    ListCubageParams,
} from '../../../../services/http/cubage.http';
import StructuresFilterSection from './FilterSection';
import ProductGroup from '../../../../constants/productGroup';
import handleResponseError from '../../../../utils/handleResponseError';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import StructuresCubage from '../../../../shared/interfaces/structure-cubage.interface';

export interface StructuresFilterProps {
    filterTerm: string;
}

export default function StructuresCubageTable() {
    const rowsPerPage = 20;
    const [showAddStructuresModal, setShowAddStructuresModal] =
        useState<boolean>(false);
    const [showRemoveStructuresModal, setShowRemoveStructuresModal] =
        useState<boolean>(false);
    const [selectedStructure, setSelectedStructure] =
        useState<StructuresCubage | null>(null);

    const form = useForm<StructuresFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
        },
    });

    const formData = form.watch();
    const termTrimmed = formData.filterTerm.trim();

    const StructuresQuery = useInfiniteQuery({
        queryKey: ['Structures-cubage', termTrimmed],
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async ({ pageParam = 0 }) => {
            const params: ListCubageParams = {
                skip: pageParam,
                page: pageParam + 1,
                take: rowsPerPage,
                term: termTrimmed || '',
            };

            const response = await CubageHttpService.readMany(
                ProductGroup.StructuresCubage,
                params,
            );

            return {
                data: response.data.data,
                currentPage: pageParam,
                pages: Math.ceil(response.data.total / rowsPerPage),
            };
        },
        getNextPageParam: ({ currentPage, pages }) =>
            currentPage + 1 < pages ? currentPage + 1 : null,
        onError: (err) => {
            handleResponseError(err, 'Erro ao buscar Estruturas');
        },
    });

    const target = useRef(null);

    useIntersectionObserver({
        target,
        onIntersect: StructuresQuery.fetchNextPage,
        enabled: StructuresQuery.hasNextPage,
    });

    const showLoading =
        StructuresQuery.isLoading ||
        StructuresQuery.isFetchingNextPage ||
        !StructuresQuery.isFetched;

    const deleteMutation = useMutation(
        async () => {
            if (!selectedStructure?.id) {
                throw new Error('Nenhum Estrutura selecionado para exclusão');
            }

            const response = await CubageHttpService.delete(
                ProductGroup.StructuresCubage,
                selectedStructure?.id,
            );

            return response.data;
        },
        {
            onError: (error: any) => {
                handleResponseError(error, 'Erro ao excluir Estrutura');
            },
            onSuccess: async () => {
                toast.success('Estrutura removido com sucesso');

                StructuresQuery.refetch();
            },
        },
    );

    const data = StructuresQuery.data?.pages.reduce(
        (accumulator, current) => accumulator.concat(current.data),
        [] as StructuresCubage[],
    );

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Cubagem dos estruturas
                    </StyledPageTitle>
                </Col>
                <Button
                    className="float-right w-20 m-2"
                    style={{ width: '180px' }}
                    onClick={() => {
                        setShowAddStructuresModal(true);
                    }}
                >
                    Nova configuração
                </Button>
            </Row>
            <StructuresFilterSection control={form.control} />
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Quantidade limite</StyledTh>
                            <StyledTh>Cubagem</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((StructureCubage: StructuresCubage) => (
                                <tr key={StructureCubage.id}>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={StructureCubage?.id.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={StructureCubage.quantityLimit.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[termTrimmed]}
                                            textToHighlight={StructureCubage.cubage.toString()}
                                        />
                                    </td>
                                    <td>
                                        <Dropdown key="left">
                                            <Dropdown.Toggle
                                                bsPrefix="nexen"
                                                className="mt-n3 mb-n3"
                                                as={Button}
                                                variant="text"
                                            >
                                                <ReactMoreIcon
                                                    fill="#bdbdbd"
                                                    width="10"
                                                    height="20"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedStructure(
                                                            StructureCubage,
                                                        );

                                                        setShowAddStructuresModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedStructure(
                                                            StructureCubage,
                                                        );

                                                        setShowRemoveStructuresModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Remover
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Row>
            <Row className="mx-4" ref={target}>
                <br />
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {data?.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showAddStructuresModal && (
                <StructuresCubageModal
                    selectedStructure={selectedStructure}
                    show={showAddStructuresModal}
                    refetch={StructuresQuery.refetch}
                    onHide={() => {
                        setSelectedStructure(null);
                        setShowAddStructuresModal(false);
                    }}
                />
            )}
            {showRemoveStructuresModal && (
                <ConfirmationDialog
                    show={showRemoveStructuresModal}
                    onHide={() => {
                        setShowRemoveStructuresModal(false);
                        setSelectedStructure(null);
                        StructuresQuery.refetch();
                    }}
                    onConfirm={async () => deleteMutation.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir Configuração de Cubagem"
                    text="Deseja excluir a configuração de cubagem selecionada?"
                />
            )}
        </>
    );
}
